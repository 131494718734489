import {
  baseApiUrlDev,
  baseApiUrlProd,
  baseApiUrlStaging,
  baseApiUrlTest,
  baseApiUrlApp,
} from 'shared/constants/api';

export const getEnv = () => {
  const host = window && window.location.host;
  return host.split('.')[0];
};

const apiUrl = (() => {
  const host = window && window.location.host;

  //return 'https://2i5fp4to8c.execute-api.us-east-1.amazonaws.com/chris';

  //
  // This is subbed out during the build process
  //
  return 'https://api.magic.firstresponder.ai';

  //return baseApiUrlDev;

})();
export default apiUrl;
